import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { FiExternalLink } from 'react-icons/fi'
import { useIsMobile } from 'Scaffold'

enum Variant {
  Video = 'Video',
  Image = 'Image',
  Blank = 'Blank',
}
export const Home = () => {
  const isMobile = useIsMobile()
  const assets = [
    {
      src: 'https://drive.google.com/uc?export=download&id=1YiLSAwQoRd2DotM-M_Q8mKQOltSxN1cu',
      alt: 'Close up nati',
      margin: '0 -25vw 0 0',
      marginMobile: '0',
      width: '25vw',
      mobileWidth: '40vw',
      variant: Variant.Video,
      hideOnMobile: true,
    },
    {
      src: 'https://drive.google.com/uc?export=download&id=1tEwZ5peauSZSMgSKR6-kbKJ6aH4EYgv_',
      alt: 'Rodriguez Spot',
      margin: '8vh -10vw 0 0',
      marginMobile: '0 0 0 -20vw',
      width: '30vw',
      mobileWidth: '70vw',
      variant: Variant.Video,
    },
    {
      src: 'https://drive.google.com/uc?export=download&id=1RWhKpMNZsqPIhhOPvRXRInCVnGui8w0N',
      alt: 'Sunset',
      margin: '-20vh 0 0 -40vw',
      marginMobile: '0 0 -30vh 0',
      width: '25vw',
      mobileWidth: '35vw',
      variant: Variant.Video,
    },
    {
      src: 'lead/02.png',
      alt: 'Sunset',
      margin: '0',
      marginMobile: '0',
      width: '30vw',
      mobileWidth: '50vw',
      variant: Variant.Blank,
    },
    {
      src: 'https://drive.google.com/uc?export=download&id=1OrGgOD-d6LpCM0omqiNdU1R4kan15pRM',
      alt: 'Team briefing',
      margin: '0 0 -10vh 0',
      marginMobile: '0 0 -25vh -5vh',
      width: '30vw',
      mobileWidth: '40vw',
      variant: Variant.Video,
    },
    {
      src: 'https://drive.google.com/uc?export=download&id=1Jne42ZsmFLrrcVGUlMZID73Utcys3_iD',
      alt: 'Riola',
      margin: '0 0 10vh 0',
      marginMobile: '0 0 -10vh 3vw',
      width: '25vw',
      mobileWidth: '50vw',
      variant: Variant.Video,
    },
  ]

  const items: Array<ReactNode> = [
    <ItemWrapper style={{ justifyContent: 'flex-end' }}>
      <h1>Featured</h1>
      <div style={{ flex: '0 0 8px' }} />
      <a
        href={'https://vimeo.com/749188427'}
        target={'_blank'}
        rel={'noreferrer'}
        style={{ display: 'flex', gap: 8, alignItems: 'center' }}
      >
        <Text>SRF Dok: Die Nati</Text>
        <FiExternalLink color={'white'} size={14} />
      </a>

      <a
        href={'https://vimeo.com/794175307/9263b50b05'}
        target={'_blank'}
        rel={'noreferrer'}
        style={{ display: 'flex', gap: 8, alignItems: 'center' }}
      >
        <Text>99%: Portrait Francisco Rodriguez</Text>
        <FiExternalLink color={'white'} size={14} />
      </a>
    </ItemWrapper>,
    <ItemWrapper>
      <h1>About</h1>
      <div style={{ flex: '0 0 8px' }} />
      <Text>
        Raphael is a dedicated filmmaker and documentarian, driven by his
        passion for storytelling and a desire to capture the beauty and
        complexity of the world around him.
      </Text>
      <div style={{ flex: '0 0 32px' }} />
      <Text>raphael_drobi@hotmail.com</Text>
      <Text>+41 78 851 51 61</Text>
    </ItemWrapper>,
  ]

  return (
    <Wrapper>
      <WrapperLead>
        <Title>{`I create motion pictures \n with empathy.`}</Title>
        {!isMobile && <TitleWrapperTopLeft />}
        {!isMobile && <TitleWrapperBottomRight />}
        {assets
          .filter((asset) => (isMobile ? !asset.hideOnMobile : true))
          .map((a, idx) => (
            <Asset
              key={idx}
              style={{
                margin: isMobile ? a.marginMobile : a.margin,
                width: isMobile ? a.mobileWidth : a.width,
              }}
            >
              {a.variant === Variant.Video ? (
                <video
                  src={a.src}
                  autoPlay
                  playsInline
                  loop
                  muted
                  style={{ width: '100%', height: '100%' }}
                ></video>
              ) : a.variant === Variant.Image ? (
                <img
                  alt={'test'}
                  src={a.src}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <div></div>
              )}
            </Asset>
          ))}
      </WrapperLead>
      <About>
        {!isMobile && <Title>{`Tell me about your next project.`}</Title>}
        {items.map((item, idx) => (
          <div key={idx}>{item}</div>
        ))}
      </About>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

const WrapperLead = styled.div`
  display: grid;
  width: 100vw;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  position: relative;
  flex: 0 0 100vh;
`

const About = styled.div`
  overflow: hidden;
  position: relative;
  ${DESKTOP_STYLE} {
    width: 100vw;
    place-items: center;
    flex: 0 0 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  ${MOBILE_STYLE} {
    flex: 0 0 460px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const Asset = styled.div`
  animation-name: shift;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  @keyframes shift {
    from {
      transform: translateX(-2vw);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${DESKTOP_STYLE} {
    padding: 80px;
    width: 50vw;
    height: 100vh;
  }
  ${MOBILE_STYLE} {
    padding: 24px;
    width: 100vw;
  }
`

const TitleWrapperTopLeft = styled.div`
  border-top: 1px solid white;
  border-left: 1px solid white;
  position: fixed;

  height: 24px;
  width: 24px;

  ${DESKTOP_STYLE} {
    top: calc(50% - 120px / 2);
    left: calc(50% - 520px / 2);
  }
  ${MOBILE_STYLE} {
    top: calc(50% - 80px / 2);
    left: calc(50% - 320px / 2);
    font-size: 20px;
  }
`

const TitleWrapperBottomRight = styled.div`
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  position: fixed;

  height: 24px;
  width: 24px;

  ${DESKTOP_STYLE} {
    top: calc(50% + 120px / 2 - 24px);
    left: calc(50% + 520px / 2 - 24px);
  }
  ${MOBILE_STYLE} {
    top: calc(50% + 80px / 2 - 24px);
    left: calc(50% + 320px / 2 - 24px);
    font-size: 20px;
  }
`

const Title = styled.h1`
  display: flex;
  text-align: center;
  font-weight: 100;
  height: 120px;
  position: absolute;
  align-items: center;

  ${DESKTOP_STYLE} {
    top: calc(50% - 120px / 2);
    height: 120px;
    left: calc(50% - 520px / 2);
    width: 520px;
  }
  ${MOBILE_STYLE} {
    top: calc(50% - 80px / 2);
    height: 80px;
    left: calc(50% - 320px / 2);
    font-size: 20px;
    width: 320px;
  }
`

const Text = styled.p`
  ${DESKTOP_STYLE} {
    font-size: 16px;
  }
  ${MOBILE_STYLE} {
    font-size: 12px;
  }
`
