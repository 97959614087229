import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'
import styled from 'styled-components'

export const EmbedVideo = (props: { link: string }) => {
  return (
    <Wrapper>
      <Iframe
        width="853"
        height="480"
        src={props.link}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded vimeo"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  flex: 0 0 480px;
  width: 100%;
  max-width: 853px;

  ${DESKTOP_STYLE}  {
    margin-top: 64px;
  }

  ${MOBILE_STYLE} {
    max-height: 280px;
    margin-top: 64px;
  }
`

const Iframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`
