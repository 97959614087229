import { EmbedVideo } from 'components/EmbedVideo'
import { useEffect, useState } from 'react'
import { useIsMobile } from 'Scaffold'
import styled from 'styled-components'
import { AiOutlineMail } from 'react-icons/ai'
import { IoIosArrowForward } from 'react-icons/io'
import { DESKTOP_STYLE, MOBILE_STYLE } from 'constants/variables'

export const Dienati = () => {
  const isMobile = useIsMobile()

  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }

    setHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Wrapper>
      <HeadingImage>
        <h1 style={{ position: 'absolute', zIndex: -1 }}>
          Die Nati - Mehr als 11 Fussballerinnen
        </h1>
        <img
          src={'heading-background.png'}
          alt={'nati poster'}
          style={{ objectFit: 'cover', width: '100%' }}
        />
        <Overlay>
          <img
            src={'participants.png'}
            alt={'nati poster'}
            style={{
              maxWidth: 480,
              width: '100%',
              objectFit: 'contain',
            }}
          />
          <HeadingWrapper>
            <img
              src={'heading.png'}
              alt={'nati poster'}
              style={{
                maxWidth: 300,
                width: '100%',
                objectFit: 'contain',
                marginTop: isMobile ? 32 : height > 500 ? 48 : 16,
              }}
            />
          </HeadingWrapper>
          <TeamWrapper>
            <img
              src={'team.png'}
              alt={'nati poster'}
              style={{
                position: 'absolute',
                bottom: 0,
                left:
                  height > 840 && !isMobile
                    ? 'calc(50% - 840px /2)'
                    : 'calc(50% - 560px /2)',
                width: height > 840 && !isMobile ? '840px' : '560px',
                display: height < 680 ? 'none' : 'block',
                objectFit: 'contain',
                zIndex: 100,
              }}
            />
          </TeamWrapper>
          <img
            src={'logoSRF.png'}
            alt={'SRF'}
            style={{
              position: 'absolute',
              bottom: 24,
              right: 40,
              // left:
              //   height > 840 && !isMobile
              //     ? 'calc(50% - 840px /2)'
              //     : 'calc(50% - 560px /2)',
              width: 80,
              objectFit: 'contain',
              zIndex: 100,
            }}
          />
        </Overlay>
      </HeadingImage>
      <Content>
        <EmbedVideo
          link={`https://player.vimeo.com/video/745794572?h=67e122be8d&color=c92d38&byline=0&portrait=0`}
        />
        <ForwardWrapper
          href="https://www.srf.ch/play/tv/redirect/detail/6440e95f-71c5-4510-a165-f81e9c0dd391"
          target="_blank"
        >
          <Atag>GANZE DOKUMENTATION AUF SRF PLAY</Atag>
          <IoIosArrowForward size={24} color="white" />
        </ForwardWrapper>
        <Contact>
          <p style={{ color: 'white', fontWeight: 'bold' }}>
            ANREGUNGEN? SCHREIB UNS
          </p>
          <IconWrapper>
            <a
              href="mailto:raphael.drobi@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineMail size={isMobile ? 48 : 64} color={'white'} />
            </a>
          </IconWrapper>
        </Contact>

        <FooterWrapper>
          <img
            src={'credits.png'}
            alt={'nati poster'}
            style={{
              width: '100%',
              maxWidth: 640,
              objectFit: 'contain',
            }}
          />
        </FooterWrapper>
      </Content>
    </Wrapper>
  )
}

//   display: flex;
//   flex: 1;
//   height: 100%;
//   justify-content: center;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`

const TeamWrapper = styled.div``

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 16px;
  align-items: center;
`

const HeadingImage = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

const Overlay = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  padding: 32px 16px 0px 16px;
`

const HeadingWrapper = styled.div``

const FooterWrapper = styled.div``

const IconWrapper = styled.div`
  margin-top: 16px;
  cursor: pointer;
  :hover {
    transform: scale(1.4);
  }
  transition: all;
  transition-duration: 1s;
`

const Contact = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 48px 0px 16px 0px;
`

const ForwardWrapper = styled.a`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 48px 0px 16px 0px;
  gap: 16px;
  border: 1px solid #f37635;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;

  :hover {
    transform: scale(1.02);
  }
`

const Atag = styled.a`
  color: white;
  font-weight: bold;
  border-bottom: 1px solid #161414;

  ${DESKTOP_STYLE}  {
    font-size: 24px;
  }

  ${MOBILE_STYLE} {
    font-size: 16px;
  }
`
